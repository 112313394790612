<template>
    <v-card>
        <v-card-title>
            <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="item.label"
                            :label="trans('fields.common.label')"
                            :error-messages="errors.label"
                            @input="clearError('label')"
                        ></v-text-field>
                        <v-text-field
                            v-model="item.url"
                            :label="trans('fields.common.url')"
                            :error-messages="errors.url"
                            @input="clearError('url')"
                        ></v-text-field>
                        <v-autocomplete
                            v-if="!cpa_network_id"
                            :items="options.cpaNetworks"
                            v-model="item.cpa_network_id"
                            :label="trans('fields.common.cpaNetwork')"
                            :error-messages="errors.cpa_network_id"
                        >
                            <template v-slot:append-outer>
                                <v-slide-x-reverse-transition
                                    mode="out-in"
                                >
                                    <v-icon color="primary" @click="cpaNetworkDialogShow">mdi-plus</v-icon>
                                </v-slide-x-reverse-transition>
                            </template>
                        </v-autocomplete>
                        <v-autocomplete
                            v-if="!offer_id"
                            :items="options.offers"
                            v-model="item.offer_id"
                            :label="trans('fields.common.offer')"
                            :error-messages="errors.offer_id"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-dialog v-model="cpaNetworkDialog" max-width="800px">
            <cpa-network-form v-if="cpaNetworkDialogLoaded" :options="cpaNetworkOptions" v-on:saved="cpaNetworkDialogOnSave" v-on:close="cpaNetworkDialogClose"></cpa-network-form>
        </v-dialog>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="$emit('close')">{{ trans('buttons.common.cancel') }}</v-btn>
            <v-btn v-if="item.id" color="primary" outlined @click="save">{{ trans('buttons.common.save') }}</v-btn>
            <v-btn v-else color="primary" outlined @click="save">{{ trans('buttons.common.create') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import formValidate from "@/mixins/formValidate";
    export default {
        name: "LinkForm",
        mixins: [formValidate],
        components: {
            CpaNetworkForm: () => import('@/components/user/cpaNetwork/CpaNetworkForm'),
        },
        props: {
            itemId: {
                type: [Number, undefined],
            },
            parentItem: {
                type: [Object, undefined],
            },
            cpa_network_id: {
                default: null,
            },
            offer_id: {
                default: null,
            },
            parentOptions: {
                type: [Object, Array, undefined],
            },
        },
        data(){
            return {
                showRoute: 'user.links.show',
                storeRoute: 'user.links.store',
                updateRoute: 'user.links.update',
                item: {
                    id: null,
                    label: '',
                    url: '',
                    cpa_network_id: this.cpa_network_id,
                    offer_id: this.offer_id,
                },
                options: {},
                cpaNetworkDialog: false,
                cpaNetworkDialogLoaded: false,
            }
        },
        created() {
            this.load();
        },
        methods: {
            load(){
                if (this.parentItem){
                    this.item = this.parentItem;
                    this.loadOptions();
                } else if(this.itemId) {
                    axios.get(this.route(this.showRoute, this.itemId)).then(response => {
                        this.item = response.data;
                        this.loadOptions();
                    }).catch(err => {
                        console.log(err);
                    })
                } else {
                    this.loadOptions();
                }
            },
            loadOptions(){
                axios.get(this.route('user.options'), {params: {
                        offers: true,
                        cpaNetworks: true,
                    }}).then( response => {
                    this.options = {
                        ...this.parentOptions,
                        ...response.data
                    };
                })
            },
            cpaNetworkDialogShow(){
                axios.get(this.route('user.options'), {params: {cpaNetworkScopes: true}})
                    .then( response => {
                        this.cpaNetworkOptions = response.data;
                        this.cpaNetworkDialogLoaded = true;
                        this.cpaNetworkDialog = true;
                    })
            },
            cpaNetworkDialogClose(){
                this.cpaNetworkDialog = false;
                setTimeout(() => {
                    this.cpaNetworkDummy = {
                        id: "",
                        label: "",
                    };
                    this.cpaNetworkDialogLoaded = false;
                }, 300);
            },
            cpaNetworkDialogOnSave(){
                this.cpaNetworkDialogClose();
                this.loadOptions();
            },

            save() {
                if (!this.item.id) {
                    axios.post(this.route(this.storeRoute), this.item).then((response) => {
                        console.log('save success');
                        this.$emit('saved', response.data.id);
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveSuccess'), type: 'success'});
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveError'), type: 'error'});
                    })
                } else {
                    axios.post(this.route(this.updateRoute, this.item.id), this.item).then((response) => {
                        console.log('save success');
                        this.$emit('saved', response.data.id);
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveSuccess'), type: 'success'});
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveError'), type: 'error'});
                    })
                }
            },
        },
        computed: {
            formTitle() {
                return !this.item.id
                    ? this.trans('pages.link.newDialogTitle')
                    : this.trans('pages.link.editDialogTitle');
            },
        }
    }
</script>
